import * as Yup from 'yup';

export const createDocumentSchema = Yup.object().shape({
  status: Yup.string().required(),
  cargoId: Yup.string().required(),
  cargoWeight: Yup.string().required(),
  recieveInChinaDate: Yup.number(),
  sendDate: Yup.number(),
  recieveInUkraineDate: Yup.number().nullable(),
  volume: Yup.string().required(),
  placeQuantity: Yup.string().required(),
  unitQuantity: Yup.string().required(),
  estimatedValue: Yup.string().required(),
  deliveryValue: Yup.string().required(),
  insuranceValue: Yup.string().required(),
  trackNumber: Yup.string(),
  additionalValue: Yup.string(),
  additionalComment: Yup.string(),
});
