import { SxProps, Theme } from '@mui/material';

interface IHeader {
  container: SxProps<Theme>;
  cell: SxProps<Theme>;
  sortCell: SxProps<Theme>;
  sortCellContainer: SxProps<Theme>;
  icon: SxProps<Theme>;
  rotateIcon: SxProps<Theme>;
  invisibleIcon: SxProps<Theme>;
}

interface IDocumentsTableStyle {
  container: SxProps<Theme>;
  header: IHeader;
  tableCell: SxProps<Theme>;
  row: SxProps<Theme>;
  pagination: SxProps<Theme>;
}

export const documentsTableStyleDesktop: IDocumentsTableStyle = {
  container: {
    width: '90%',
    height: '100%',
    marginLeft: '20px',
    paddingLeft: '15px',
    paddingRight: '15px',
    paddingBottom: '15px',
    display: 'flex',
    alignItems: 'flex-start',
    flexWrap: 'wrap',
    borderRadius: '10px',
    backgroundColor: 'primary.dark',
    overflow: 'hidden',
    overflowY: 'scroll',
    overflowX: 'scroll',
    scrollbarWidth: '0',
    '::-webkit-scrollbar': {
      width: 0,
      height: 0,
    },
  },
  header: {
    container: {
      borderColor: 'secondary.main',
    },
    cell: {
      color: 'secondary.main',
      textAlign: 'center',
    },
    sortCell: {
      color: 'secondary.main',
      textAlign: 'center',
      '&:hover': {
        color: 'secondary.dark',
        cursor: 'pointer',
      },
    },
    sortCellContainer: {
      display: 'flex',
      width: '100%',
      justifyContent: 'center',
      alignItems: 'center',
    },
    icon: {
      transition: '.5s',
      opacity: 1,
      color: 'white',
      fontSize: '20px',
    },
    rotateIcon: {
      transform: 'rotate(180deg)',
      opacity: 1,
      color: 'white',
      fontSize: '20px',
    },
    invisibleIcon: {
      opacity: 0,
      color: 'white',
      fontSize: '20px',
    },
  },
  tableCell: {
    color: 'white',
    textAlign: 'center',
  },
  row: {
    height: '53px',
  },
  pagination: {
    margin: '0 auto',
    marginTop: 'auto',
    paddingTop: '10px',

    button: { color: 'secondary.main' },
  },
};

export const documentsTableStyleMobile: IDocumentsTableStyle = {
  container: {
    width: '90%',
    height: '100%',
    paddingBottom: '15px',
    display: 'flex',
    flexWrap: 'wrap',
    whiteSpace: 'nowrap',
    alignItems: 'flex-start',
    borderRadius: '10px',
    backgroundColor: 'primary.dark',
    overflow: 'hidden',
    overflowY: 'scroll',
    overflowX: 'scroll',
    scrollbarWidth: '0',
    '::-webkit-scrollbar': {
      width: 0,
      height: 0,
    },
  },
  header: {
    container: {
      borderColor: 'secondary.main',
    },
    cell: {
      color: 'secondary.main',
      textAlign: 'center',
      paddingBottom: '0px',
      paddingTop: '0px',
      fontSize: '15px',
    },
    sortCell: {
      color: 'secondary.main',
      textAlign: 'center',
      '&:hover': {
        color: 'secondary.dark',
        cursor: 'pointer',
      },
    },
    sortCellContainer: {
      display: 'flex',
      width: '100%',
      justifyContent: 'center',
      alignItems: 'center',
    },
    icon: {
      transition: '.5s',
      opacity: 1,
      color: 'white',
      fontSize: '20px',
    },
    rotateIcon: {
      transform: 'rotate(180deg)',
      opacity: 1,
      color: 'white',
      fontSize: '20px',
    },
    invisibleIcon: {
      opacity: 0,
      color: 'white',
      fontSize: '20px',
    },
  },
  tableCell: {
    color: 'white',
    textAlign: 'center',
  },
  row: {
    height: '75px',
  },
  pagination: {
    margin: '0 auto',
    marginTop: 'auto',
    paddingTop: '10px',
    position: 'sticky',
    left: '50%',
    transform: 'translate(-50%, 0)',
    button: { color: 'secondary.main' },
  },
};
