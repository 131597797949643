import { Box } from '@mui/material';

import { PersonalInfo } from '../../Components/PersonalInfo/PersonalInfo';

import { pageStyle } from './HomePageStyle';
import { useUserStore } from '../../Store/userStore';
import { Roles } from '../../Types/roles';
import { ManageUsers } from '../../Components/ManageUsers/ManageUsers';

export const HomePage = () => {
  const { user } = useUserStore();

  return (
    <Box sx={pageStyle}>
      {(user?.role === Roles.Admin && <ManageUsers />) || <PersonalInfo />}
    </Box>
  );
};
