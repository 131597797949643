import axios, { AxiosError, InternalAxiosRequestConfig } from "axios";

import { BASE_URL, LOCAL_STORAGE_KEY } from "../../Constants/constants";
import { getFromStorage, removeFromStorage } from "../localstorage";

import {
  ILoginInput,
  ILoginResponse,
} from "../../Components/AuthForm/AuthFromType";
import { IUserInfo } from "../../Components/PersonalInfo/PersonalInfoType";
import { IApiDocument } from "../../Types/Document/api.document";
import { IApiPagination } from "../../Types/Pagination/api.pagination";
import { OrderBy } from "../../Types/orderType";

export const instance = axios.create({
  baseURL: BASE_URL,
});

const requestTokenInterseptor = (request: InternalAxiosRequestConfig) => {
  const { headers, ...other } = request;
  const token = getFromStorage({ key: LOCAL_STORAGE_KEY });
  if (token && headers) headers["Authorization"] = `Bearer ${token}`;
  return {
    headers,
    ...other,
  };
};

const handleForbiddenToken = (error: AxiosError) => {
  if (error.response && error.response.status === 403) {
    removeFromStorage({ key: LOCAL_STORAGE_KEY });
  }
  return Promise.reject(error);
};

instance.interceptors.request.use(requestTokenInterseptor);
instance.interceptors.response.use(
  (response) => response,
  handleForbiddenToken
);

export const loginUserFn = async (user: ILoginInput) => {
  const response = await instance.post<ILoginResponse>("auth/login", user);
  return response.data;
};

export const getUserInfoFn = async () => {
  const response = await instance.get<IUserInfo>("user/me");
  return response.data;
};

export const getDocumentsFn = async (
  page: number,
  rowsPerPage: number,
  searchId: string,
  orderBy: OrderBy,
  isDesc: boolean
) => {
  const response = await instance.get<IApiPagination<IApiDocument>>(
    `document/my?page=${page}&limit=${rowsPerPage}&cargoId=${searchId}&orderBy=${orderBy}&desc=${isDesc}`
  );
  return response.data;
};
