import dayjs from 'dayjs';
import { IDocumentCreate } from '../../Types/Document/document.create';
import { IDocumentUpdate } from '../../Types/Document/document.update';
import { Status } from '../../Types/status.enum';

export const initialValues: IDocumentCreate & IDocumentUpdate = {
  status: Status.inChina,
  cargoId: '',
  cargoWeight: '',
  recieveInChinaDate: dayjs().unix(),
  sendDate: dayjs().unix(),
  recieveInUkraineDate: null,
  volume: '',
  placeQuantity: '',
  unitQuantity: '',
  estimatedValue: '',
  deliveryValue: '',
  insuranceValue: '',
  additionalValue: '',
  additionalComment: '',
  trackNumber: '',
  userId: 0,
  id: 0,
};
