import { useEffect, useState } from 'react';
import { getMyDocuments } from '../../Service/axios/document.api';
import { applicationStore } from '../../Store/applicationStore';
import { IApiPagination } from '../../Types/Pagination/api.pagination';
import { IApiDocument } from '../../Types/Document/api.document';
import { useQuery } from '@tanstack/react-query';
import { OrderBy } from '../../Types/orderType';
import { Box } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { SearchField } from '../SearchField/SearchField';
import { DocumentsTable } from '../DocumentsTable/DocumentsTable';
import { deliviriesStyleDesktop, deliviriesStyleMobile } from './style';
import { DocumentModal } from '../DocumentModal/DocumentModal';

export const Deliviries = () => {
  const { searchId } = applicationStore();
  const [currentDocument, setCurrentDocument] = useState<IApiDocument>();
  const [open, setOpen] = useState(false);
  const [page, setPage] = useState(1);
  const [desc, setDesc] = useState(false);
  const [orderBy, setOrderBy] = useState(OrderBy.id);

  const matches = useMediaQuery('(max-width:1000px)');

  const limit = 10;

  const style = matches ? deliviriesStyleMobile : deliviriesStyleDesktop;

  const { data: response, refetch } = useQuery<IApiPagination<IApiDocument>>({
    queryKey: ['my-documents', page, searchId, limit],
    queryFn: () =>
      getMyDocuments({
        searchId,
        page,
        desc,
        orderBy,
        limit,
      }),
    enabled: false,
  });

  useEffect(() => {
    refetch();
  }, [page, desc, orderBy, searchId, limit]);

  const handleClose = () => {
    setOpen(false);
  };

  const handldeOpen = (document: IApiDocument) => {
    setCurrentDocument(document);
    setOpen(true);
  };

  return (
    <Box sx={style.container}>
      <Box sx={style.filtersContainer}>
        <SearchField />
      </Box>
      <Box sx={style.tableContainer}>
        <DocumentsTable
          page={page}
          setPage={setPage}
          desc={desc}
          setDesc={setDesc}
          orderBy={orderBy}
          setOrderBy={setOrderBy}
          items={response?.items}
          meta={response?.meta}
          setCurrentDocument={handldeOpen}
        />
      </Box>
      {currentDocument && (
        <DocumentModal
          open={open}
          handleClose={handleClose}
          document={currentDocument}
        />
      )}
    </Box>
  );
};
