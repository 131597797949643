import * as Yup from 'yup';

export const createUserSchema = Yup.object().shape({
  email: Yup.string().email('Некоректна пошта').required(),
  password: Yup.string(),
  name: Yup.string().required(),
  surname: Yup.string().required(),
  clientNumber: Yup.string().required(),
  balance: Yup.string().required(),
  tarif: Yup.string().required(),
  storageAdressInChina: Yup.string().required(),
  storageAdressInUkraine: Yup.string().required(),
  managerName: Yup.string().required(),
  managerSurname: Yup.string().required(),
  managerPhone: Yup.string().required(),
});
