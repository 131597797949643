import { SxProps, Theme } from '@mui/material';

interface IDeliviriesStyle {
  container: SxProps<Theme>;
  filtersContainer: SxProps<Theme>;
  tableContainer: SxProps<Theme>;
}

export const deliviriesStyleDesktop: IDeliviriesStyle = {
  container: {
    width: '100vw',
    height: '100dvh',
    padding: 0,
    margin: 0,
    display: 'flex',
    alignItems: 'flex-start',
    paddingTop: '50px',
  },
  filtersContainer: {
    width: '20%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    paddingLeft: '30px',
  },
  tableContainer: {
    width: '80%',
    height: '60%',
  },
};

export const deliviriesStyleMobile: IDeliviriesStyle = {
  container: {
    width: '100vw',
    height: '100dvh',
    padding: 0,
    margin: 0,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  filtersContainer: {
    height: '10%',
    width: '90%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: '15px',
  },
  tableContainer: {
    height: '70%',
    width: '100%',
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'center',
  },
};
