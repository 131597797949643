import { Box } from '@mui/material';

import { pageStyle } from './DeliveriesPageStyle';
import { useUserStore } from '../../Store/userStore';
import { Roles } from '../../Types/roles';
import { ManageDeliveries } from '../../Components/ManageDeliveries/ManageDeliveries';
import { Deliviries } from '../../Components/Deliviries/Deliviries';

export const DeliveriesPage = () => {
  const { user } = useUserStore();

  return (
    <Box sx={pageStyle}>
      {(user?.role === Roles.Admin && <ManageDeliveries />) || <Deliviries />}
    </Box>
  );
};
