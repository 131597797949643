import { Box, TextField } from '@mui/material';

import { applicationStore } from '../../Store/applicationStore';

import { containerStyle, searchFieldStyle } from './SearchFieldStyle';

export const SearchField = () => {
  const { currentPage, setCurrentPage, searchId, setSearchId } =
    applicationStore();

  const onChangeSearchField = (event: React.ChangeEvent<HTMLInputElement>) => {
    currentPage && setCurrentPage(1);
    setSearchId(event.target.value);
  };

  return (
    <TextField
      fullWidth
      sx={searchFieldStyle}
      value={searchId}
      onChange={onChangeSearchField}
      label="Пошук за номером вантажу"
      color="secondary"
      focused
    />
  );
};
