import { useNavigate } from 'react-router-dom';

import { Box, Button, Slide } from '@mui/material';

import { applicationStore } from '../../Store/applicationStore';
import { useUserStore } from '../../Store/userStore';

import { removeFromStorage } from '../../Service/localstorage';
import { LOCAL_STORAGE_KEY } from '../../Constants/constants';

import { menuStyle, buttonStyle } from './MenuStyle';

export const Menu = () => {
  const { menuOnScreen, setMenuOnScreen } = applicationStore();
  const { setUser } = useUserStore();
  const navigate = useNavigate();

  const handleLogout = () => {
    removeFromStorage({ key: LOCAL_STORAGE_KEY });
    setUser(undefined);
    setMenuOnScreen();
  };

  const handleNavigateClick = (route: string) => {
    navigate(route);
    setMenuOnScreen();
  };

  return (
    <Slide direction="left" in={menuOnScreen} mountOnEnter unmountOnExit>
      <Box sx={menuStyle}>
        <Button sx={buttonStyle} onClick={() => handleNavigateClick('/home')}>
          Головна
        </Button>
        <Button
          sx={buttonStyle}
          onClick={() => handleNavigateClick('/deliveries')}
        >
          Вантажі
        </Button>
        <Button
          sx={{ ...buttonStyle, marginTop: 'auto' }}
          onClick={handleLogout}
        >
          Вихід
        </Button>
      </Box>
    </Slide>
  );
};
