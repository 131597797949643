import { QueryFunctionContext, QueryKey } from 'react-query';
import { instance } from './axios';
import { OrderBy } from '../../Types/orderType';
import { IDocumentCreate } from '../../Types/Document/document.create';
import { IDocumentUpdate } from '../../Types/Document/document.update';

interface IPaginationParams {
  page?: number;
  searchId?: string;
  id?: number;
  limit?: number;
  desc?: boolean;
  orderBy?: OrderBy;
}

export const getDocumentsByUserId = async ({
  page = 1,
  searchId,
  id,
  limit,
  orderBy,
  desc = false,
}: IPaginationParams) => {
  return (
    await instance.get(
      `document/user/${id}?page=${page}&cargoId=${searchId}&limit=${limit}&orderBy=${orderBy}&desc=${desc}`
    )
  ).data;
};

export const getMyDocuments = async ({
  page,
  searchId,
  limit,
  desc,
  orderBy,
}: IPaginationParams) => {
  return (
    await instance.get(
      `document/my?page=${page}&limit=${limit}&cargoId=${searchId}&orderBy=${orderBy}&desc=${desc}`
    )
  ).data;
};

export const createDocument = async (document: IDocumentCreate) => {
  return instance.post('document', document);
};

export const updateDocument = async ({ id, ...document }: IDocumentUpdate) => {
  return instance.post(`document/update/${id}`, document);
};

export const deleteDocument = async (id: number) => {
  return instance.delete(`document/${id}`);
};
