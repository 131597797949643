interface IStorageObject {
  key: string;
  value: any;
}

export const saveInStorage = ({ key, value }: IStorageObject) => {
  localStorage.setItem(key, JSON.stringify(value));
};

export const getFromStorage = ({ key }: Pick<IStorageObject, "key">) => {
  const testValue = localStorage.getItem(key);
  if (testValue) {
    return JSON.parse(testValue);
  }
  return null;
};

export const removeFromStorage = ({ key }: Pick<IStorageObject, "key">) => {
  return localStorage.removeItem(key);
};
