import { SxProps, Theme } from '@mui/material';

interface IPersonalInfoStyle {
  containerStyle: SxProps<Theme>;
  fieldLabelStyle: SxProps<Theme>;
  sectionLabelStyle: SxProps<Theme>;
  infoStyle: SxProps<Theme>;
}

export const personalInfoStyleDesktop: IPersonalInfoStyle = {
  containerStyle: {
    width: '850px',
    margin: 'auto',
    marginTop: '20px',
    paddingLeft: '15px',
    paddingRight: '15px',
    paddingBottom: '15px',

    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'flex-end',

    borderRadius: '10px',

    backgroundColor: 'primary.dark',
  },
  fieldLabelStyle: {
    width: '50%',

    marginTop: '5px',
    marginBottom: '5px',

    boxSizing: 'border-box',

    borderRight: '1px solid',
    borderBottom: '1px solid',
    borderColor: 'secondary.main',

    fontSize: '30px',
    textAlign: 'left',
    color: 'secondary.main',
  },
  sectionLabelStyle: {
    width: '100%',

    fontSize: '40px',
    textAlign: 'center',
    color: 'secondary.main',
  },
  infoStyle: {
    width: '50%',

    marginBottom: '5px',
    paddingTop: '5px',
    paddingLeft: '10px',

    boxSizing: 'border-box',

    borderBottom: '1px solid',
    borderColor: 'secondary.main',

    fontSize: '25px',
    fontWeight: 'bold',
    color: 'white',
  },
};

export const personalInfoStyleMobile: IPersonalInfoStyle = {
  containerStyle: {
    width: '80%',
    height: '85%',
    maxHeight: '700px',
    overflowY: 'scroll',
    scrollbarWidth: '0',
    '::-webkit-scrollbar': {
      width: 0,
      height: 0,
    },

    margin: 'auto',
    marginTop: '20px',
    paddingLeft: '15px',
    paddingRight: '15px',
    paddingBottom: '15px',

    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'flex-end',

    borderRadius: '10px',

    backgroundColor: 'primary.dark',
  },
  fieldLabelStyle: {
    width: '100%',
    boxSizing: 'border-box',

    fontSize: '15px',
    textAlign: 'left',
    color: 'secondary.main',
  },
  sectionLabelStyle: {
    width: '100%',

    fontSize: '22px',
    textAlign: 'center',
    color: 'secondary.main',
  },
  infoStyle: {
    width: '100%',

    marginBottom: '5px',

    boxSizing: 'border-box',

    fontSize: '20px',
    fontWeight: 'bold',
    color: 'white',
  },
};
