import { Box } from "@mui/material/";

import { AuthForm } from "../../Components/AuthForm/AuthForm";

import { pageStyle, backgroundStyle } from "./LoginPageStyle";

export const LoginPage = () => {
  return (
    <Box sx={pageStyle}>
      <Box sx={backgroundStyle} />
      <AuthForm />
    </Box>
  );
};
