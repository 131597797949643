import { QueryFunctionContext } from 'react-query';
import { IUserCreate } from '../../Types/User/user.create';
import { IUserUpdate } from '../../Types/User/user.update';
import { instance } from './axios';

export const getAllUsers = async ({ pageParam = 1 }: QueryFunctionContext) => {
  const response = await instance.get(`user/all?page=${pageParam}`);

  return response.data;
};

export const createUser = async (user: IUserCreate) => {
  return instance.post('auth/register', user);
};

export const updateUser = async ({ id, ...user }: IUserUpdate) => {
  return instance.post(`user/update/${id}`, user);
};

export const deleteUser = async (id: number) => {
  return instance.delete(`user/${id}`);
};
