import { Box, Button, Modal, TextField, useMediaQuery } from '@mui/material';
import { useFormik } from 'formik';
import { userModalStyleDesktop, userModalStyleMobile } from './style';
import { initialValues } from './initial';
import { createUserSchema } from './validation';
import { IUserCreate } from '../../Types/User/user.create';
import { IUserUpdate } from '../../Types/User/user.update';
import { IApiUser } from '../../Types/User/api.user';

interface IUserModal {
  open: boolean;
  handleClose: () => void;
  handleDelete: (id: number) => void;
  handleSubmit: (user: IUserCreate & IUserUpdate) => void;
  user?: IApiUser;
}

export const UserModal = ({
  open,
  handleClose,
  handleSubmit,
  handleDelete,
  user,
}: IUserModal) => {
  const formik = useFormik<IUserCreate & IUserUpdate>({
    initialValues: {
      ...initialValues,
      ...user,
    },
    validationSchema: createUserSchema,
    onSubmit: handleSubmit,
    validateOnBlur: false,
    validateOnChange: false,
    enableReinitialize: true,
  });

  const onClose = () => {
    handleClose();
  };

  const matches = useMediaQuery('(max-width:480px)');

  const style = matches ? userModalStyleMobile : userModalStyleDesktop;

  return (
    <Modal open={open} onClose={onClose}>
      <Box sx={style.container}>
        <form
          style={{ width: '100%' }}
          autoComplete="off"
          onSubmit={formik.handleSubmit}
        >
          <Box sx={style.form}>
            <Box sx={style.column}>
              <TextField
                id="email"
                name="email"
                variant="standard"
                label="Пошта"
                value={formik.values.email}
                onChange={formik.handleChange}
                error={Boolean(formik.errors.email)}
                autoComplete={'off'}
                color="secondary"
                sx={style.field}
                fullWidth
                inputProps={{ style: style.input }}
                InputLabelProps={{ style: style.label }}
              />
              <TextField
                id="name"
                name="name"
                variant="standard"
                label="Ім'я"
                value={formik.values.name}
                onChange={formik.handleChange}
                error={Boolean(formik.errors.name)}
                autoComplete={'off'}
                color="secondary"
                sx={style.field}
                fullWidth
                inputProps={{ style: style.input }}
                InputLabelProps={{ style: style.label }}
              />
              <TextField
                id="surname"
                name="surname"
                variant="standard"
                label="Прізвище"
                value={formik.values.surname}
                onChange={formik.handleChange}
                error={Boolean(formik.errors.surname)}
                autoComplete={'off'}
                color="secondary"
                sx={style.field}
                fullWidth
                inputProps={{ style: style.input }}
                InputLabelProps={{ style: style.label }}
              />
              <TextField
                id="clientNumber"
                name="clientNumber"
                variant="standard"
                label="Код клієнта"
                value={formik.values.clientNumber}
                onChange={formik.handleChange}
                error={Boolean(formik.errors.clientNumber)}
                autoComplete={'off'}
                color="secondary"
                sx={style.field}
                fullWidth
                inputProps={{ style: style.input }}
                InputLabelProps={{ style: style.label }}
              />
              <TextField
                id="balance"
                name="balance"
                variant="standard"
                label="Баланс"
                value={formik.values.balance}
                onChange={formik.handleChange}
                error={Boolean(formik.errors.balance)}
                autoComplete={'off'}
                color="secondary"
                sx={style.field}
                fullWidth
                inputProps={{ style: style.input }}
                InputLabelProps={{ style: style.label }}
              />
              <TextField
                id="tarif"
                name="tarif"
                variant="standard"
                label="Тариф"
                value={formik.values.tarif}
                onChange={formik.handleChange}
                error={Boolean(formik.errors.tarif)}
                autoComplete={'off'}
                color="secondary"
                sx={style.field}
                fullWidth
                inputProps={{ style: style.input }}
                InputLabelProps={{ style: style.label }}
              />
            </Box>
            <Box sx={style.column}>
              <TextField
                id="storageAdressInUkraine"
                name="storageAdressInUkraine"
                variant="standard"
                label="Адреса доставки в Україні"
                value={formik.values.storageAdressInUkraine}
                onChange={formik.handleChange}
                error={Boolean(formik.errors.storageAdressInUkraine)}
                autoComplete={'off'}
                color="secondary"
                sx={style.field}
                fullWidth
                inputProps={{ style: style.input }}
                InputLabelProps={{ style: style.label }}
              />
              <TextField
                id="storageAdressInChina"
                name="storageAdressInChina"
                variant="standard"
                label="Адреса складу в Китаї"
                value={formik.values.storageAdressInChina}
                onChange={formik.handleChange}
                error={Boolean(formik.errors.storageAdressInChina)}
                autoComplete={'off'}
                color="secondary"
                sx={style.field}
                fullWidth
                inputProps={{ style: style.input }}
                InputLabelProps={{ style: style.label }}
              />
              <TextField
                id="managerName"
                name="managerName"
                variant="standard"
                label="Ім'я менеджера"
                value={formik.values.managerName}
                onChange={formik.handleChange}
                error={Boolean(formik.errors.managerName)}
                autoComplete={'off'}
                color="secondary"
                sx={style.field}
                fullWidth
                inputProps={{ style: style.input }}
                InputLabelProps={{ style: style.label }}
              />
              <TextField
                id="managerSurname"
                name="managerSurname"
                variant="standard"
                label="Прізвище менеджера"
                value={formik.values.managerSurname}
                onChange={formik.handleChange}
                error={Boolean(formik.errors.managerSurname)}
                autoComplete={'off'}
                color="secondary"
                sx={style.field}
                fullWidth
                inputProps={{ style: style.input }}
                InputLabelProps={{ style: style.label }}
              />
              <TextField
                id="managerPhone"
                name="managerPhone"
                variant="standard"
                label="Телефон менеджера"
                value={formik.values.managerPhone}
                onChange={formik.handleChange}
                error={Boolean(formik.errors.managerPhone)}
                autoComplete={'off'}
                color="secondary"
                sx={style.field}
                fullWidth
                inputProps={{ style: style.input }}
                InputLabelProps={{ style: style.label }}
              />
              {!user && (
                <TextField
                  id="password"
                  name="password"
                  variant="standard"
                  label="Пароль"
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  error={Boolean(formik.errors.password)}
                  autoComplete={'off'}
                  color="secondary"
                  sx={style.field}
                  fullWidth
                  inputProps={{ style: style.input }}
                  InputLabelProps={{ style: style.label }}
                />
              )}
            </Box>
          </Box>
          <Box sx={style.row}>
            <Button
              sx={style.button}
              color="secondary"
              variant="contained"
              type="submit"
            >
              {user ? 'Оновити клієнта' : 'Створити клієнта'}
            </Button>
            {user && (
              <Button
                sx={style.button}
                color="error"
                variant="contained"
                onClick={() => handleDelete(user.id)}
              >
                Видалити клієнта
              </Button>
            )}
          </Box>
        </form>
      </Box>
    </Modal>
  );
};
