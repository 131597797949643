import { Box, Button, Modal, Typography } from '@mui/material';
import { confirmationModalStyle } from './style';

interface IConfirmationModal {
  open: boolean;
  handleClose: () => void;
  handleConfirmation: () => void;
  handleReject?: () => void;
  title: string;
}

export const ConfirmationModal = ({
  open,
  handleClose,
  handleConfirmation,
  handleReject,
  title,
}: IConfirmationModal) => {
  const onClose = () => {
    handleReject && handleReject();
    handleClose();
  };

  const onConfirm = () => {
    handleConfirmation();
    handleClose();
  };
  return (
    <Modal open={open} onClose={onClose}>
      <Box sx={confirmationModalStyle.container}>
        <Typography>{title}</Typography>
        <Box sx={confirmationModalStyle.row}>
          <Button variant="contained" color="success" onClick={onClose}>
            Ні
          </Button>
          <Button variant="contained" color="error" onClick={onConfirm}>
            Так
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};
