import { create } from "zustand";

interface IApplicationStoreValues {
  menuOnScreen: boolean;
  passwordIsVisible: boolean;
  currentPage: number;
  searchId: string;
  error: string;
}

interface IApplicationStore extends IApplicationStoreValues {
  setMenuOnScreen: () => void;
  setPasswordIsVisible: () => void;
  setCurrentPage: (page: number) => void;
  setSearchId: (id: string) => void;
  setError: (errorMessage: string) => void;
}

const initialStoreValues: IApplicationStoreValues = {
  menuOnScreen: false,
  passwordIsVisible: false,
  currentPage: 1,
  searchId: "",
  error: "",
};

export const applicationStore = create<IApplicationStore>((set) => ({
  ...initialStoreValues,
  setMenuOnScreen: () =>
    set((state) => ({ menuOnScreen: !state.menuOnScreen })),
  setPasswordIsVisible: () =>
    set((state) => ({ passwordIsVisible: !state.passwordIsVisible })),
  setCurrentPage: (page) => set(() => ({ currentPage: page })),
  setSearchId: (id) => set(() => ({ searchId: id })),
  setError: (errorMessage) => set(() => ({ error: errorMessage })),
}));
