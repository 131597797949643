import { create } from "zustand";

import { IUserInfo } from "../Components/PersonalInfo/PersonalInfoType";

interface IUser {
  user: IUserInfo | undefined;
  setUser: (value: IUserInfo | undefined) => void;
}

export const useUserStore = create<IUser>((set) => ({
  user: undefined,
  setUser: (user) => set(() => ({ user: user })),
}));
