import { useEffect, useState } from 'react';
import {
  Box,
  Button,
  CircularProgress,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { useQuery, useMutation, useInfiniteQuery } from '@tanstack/react-query';

import { User } from '../User/User';
import { UserModal } from '../UserModal/UserModal';

import {
  createUser,
  deleteUser,
  getAllUsers,
  updateUser,
} from '../../Service/axios/user.api';

import { IApiUser } from '../../Types/User/api.user';
import { IUserCreate } from '../../Types/User/user.create';
import { IUserUpdate } from '../../Types/User/user.update';

import { manageUsersStyleDesktop, manageUsersStyleMobile } from './style';
import { ConfirmationModal } from '../ConfirmationModal/ConfirmationModal';
import { IApiPagination } from '../../Types/Pagination/api.pagination';
import { Waypoint } from 'react-waypoint';

export const ManageUsers = () => {
  const [isOpenUser, setOpenUser] = useState<boolean>(false);
  const [isOpenConfirmation, setOpenConfirmation] = useState<boolean>(false);
  const [currentUser, setCurrentUser] = useState<IApiUser>();
  const [deleteId, setDeleteId] = useState<number>();
  const {
    data: response,
    isLoading,
    hasNextPage,
    fetchNextPage,
    refetch,
  } = useInfiniteQuery<IApiPagination<IApiUser>>({
    queryKey: ['users'],
    queryFn: getAllUsers,
    getNextPageParam: ({ meta: { currentPage, totalPages } }) => {
      return (currentPage < totalPages && currentPage + 1) || undefined;
    },
  });
  const createUserMutation = useMutation(
    (user: IUserCreate) => createUser(user),
    { onSuccess: () => refetch() }
  );
  const updateUserMutation = useMutation(
    (user: IUserUpdate) => updateUser(user),
    { onSuccess: () => refetch() }
  );
  const deleteUserMutation = useMutation((id: number) => deleteUser(id), {
    onSuccess: () => refetch(),
  });

  const handleEditUser = (user: IApiUser) => {
    setCurrentUser(user);
    setOpenUser(true);
  };

  const handleDeleteUser = (id: number) => {
    setDeleteId(id);
    setOpenConfirmation(true);
  };

  const handleConfirmDelete = () => {
    setOpenUser(false);
    deleteId && deleteUserMutation.mutate(deleteId);
    setDeleteId(undefined);
  };

  const handleReject = () => {
    setDeleteId(undefined);
  };

  const handleClickCreate = () => {
    setCurrentUser(undefined);
    setOpenUser(true);
  };

  const handleClose = (setState: (state: boolean) => void) => {
    return () => setState(false);
  };

  const handleSubmit = ({
    id,
    password,
    ...user
  }: IUserCreate & IUserUpdate) => {
    currentUser
      ? updateUserMutation.mutate({ id, ...user })
      : createUserMutation.mutate({ password, ...user });

    setOpenUser(false);
    setCurrentUser(undefined);
  };

  const matches = useMediaQuery('(max-width:480px)');

  const style = matches ? manageUsersStyleMobile : manageUsersStyleDesktop;

  if (isLoading) {
    return <CircularProgress />;
  }

  return (
    <Box sx={style.container}>
      <Box sx={style.header}>
        <Box sx={style.headerCell}>
          <Typography variant="subtitle1">Код клієнта</Typography>
        </Box>
        <Box sx={style.headerCell}>
          <Typography variant="subtitle1">Ім'я</Typography>
        </Box>
        <Box sx={style.headerCell}>
          <Typography variant="subtitle1">Баланс</Typography>
        </Box>
      </Box>
      <Box sx={style.usersList}>
        {response?.pages.map((page) =>
          page.items.map((user) => (
            <User
              {...user}
              handleDelete={handleDeleteUser}
              handleEdit={handleEditUser}
              key={user.id}
            />
          ))
        )}
        <Waypoint onEnter={() => hasNextPage && fetchNextPage()} />
      </Box>
      <Button
        sx={style.button}
        fullWidth={false}
        variant="contained"
        color="secondary"
        onClick={handleClickCreate}
      >
        Створити клієнта
      </Button>
      <UserModal
        open={isOpenUser}
        handleClose={handleClose(setOpenUser)}
        handleDelete={handleDeleteUser}
        handleSubmit={handleSubmit}
        user={currentUser}
      />
      <ConfirmationModal
        open={isOpenConfirmation}
        handleClose={handleClose(setOpenConfirmation)}
        handleConfirmation={handleConfirmDelete}
        handleReject={handleReject}
        title="Ви дійсно бажаєте видалити цього клієнта?"
      />
    </Box>
  );
};
