import { SxProps, Theme } from '@mui/material';

export const pageStyle: SxProps<Theme> = {
  width: '100dvw',
  height: '100dvh',

  padding: 0,
  margin: 0,

  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};

export const backgroundStyle: SxProps<Theme> = {
  width: '100%',
  height: '100%',

  padding: 0,
  margin: 0,

  position: 'absolute',
  top: 0,
  zIndex: -1,
  backgroundColor: 'primary.dark',
};
