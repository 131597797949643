import { Outlet } from 'react-router-dom';

import { Box, SxProps, useMediaQuery, Theme } from '@mui/material';

import { Header } from '../Components/Header/Header';
import { Menu } from '../Components/Menu/Menu';

const desktopStyle: SxProps<Theme> = {
  display: 'flex',
  flexDirection: 'column',
  maxHeight: '100dvh',
  overflow: 'hidden',
  overflowY: 'scroll',
  overflowX: 'scroll',
  scrollbarWidth: '0',
  '::-webkit-scrollbar': {
    width: 0,
    height: 0,
  },
  backgroundColor: 'primary.main',
};

const mobileStyle: SxProps<Theme> = {
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: 'primary.main',
};

export const LayoutRoute = () => {
  const matches = useMediaQuery('(max-width:480px)');

  const style = matches ? mobileStyle : desktopStyle;

  return (
    <Box sx={style}>
      <Header />
      <Outlet />
      <Menu />
    </Box>
  );
};
