import {
  Box,
  ImageList,
  ImageListItem,
  Modal,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { IApiDocument } from '../../Types/Document/api.document';
import { documentModalStyleDesktop, documentModalStyleMobile } from './style';
import dayjs from 'dayjs';

interface IDocumentModal {
  open: boolean;
  handleClose: () => void;
  document: IApiDocument;
}

export const DocumentModal = ({
  open,
  handleClose,
  document,
}: IDocumentModal) => {
  const matches = useMediaQuery('(max-width:480px)');

  const style = matches ? documentModalStyleMobile : documentModalStyleDesktop;

  return (
    <Modal open={open} onClose={handleClose}>
      <Box sx={style.container}>
        <Box sx={style.cell}>
          <Typography sx={style.label} variant="h6">
            Статус
          </Typography>
          <Typography variant="body1">{document.status}</Typography>
        </Box>
        <Box sx={style.cell}>
          <Typography sx={style.label} variant="h6">
            Номер вантажу
          </Typography>
          <Typography variant="body1">{document.cargoId}</Typography>
        </Box>
        <Box sx={style.cell}>
          <Typography sx={style.label} variant="h6">
            Вага (кг)
          </Typography>
          <Typography variant="body1">{document.cargoWeight}</Typography>
        </Box>
        <Box sx={style.cell}>
          <Typography sx={style.label} variant="h6">
            Об'єм (м3)
          </Typography>
          <Typography variant="body1">{document.volume}</Typography>
        </Box>
        <Box sx={style.cell}>
          <Typography sx={style.label} variant="h6">
            Вартість доставки
          </Typography>
          <Typography variant="body1">{document.deliveryValue}</Typography>
        </Box>
        <Box sx={style.cell}>
          <Typography sx={style.label} variant="h6">
            Оціночна вартість
          </Typography>
          <Typography variant="body1">{document.estimatedValue}</Typography>
        </Box>
        <Box sx={style.cell}>
          <Typography sx={style.label} variant="h6">
            Вартість страховки
          </Typography>
          <Typography variant="body1">{document.insuranceValue}</Typography>
        </Box>
        <Box sx={style.cell}>
          <Typography sx={style.label} variant="h6">
            Кількість одиниць
          </Typography>
          <Typography variant="body1">{document.unitQuantity}</Typography>
        </Box>
        <Box sx={style.cell}>
          <Typography sx={style.label} variant="h6">
            Кількість місць
          </Typography>
          <Typography variant="body1">{document.placeQuantity}</Typography>
        </Box>
        {document.trackNumber && (
          <Box sx={style.cell}>
            <Typography sx={style.label} variant="h6">
              Трек номер по Китаю
            </Typography>
            <Typography variant="body1">{document.trackNumber}</Typography>
          </Box>
        )}
        <Box sx={style.cell}>
          <Typography sx={style.label} variant="h6">
            Дата отримання в Китаї
          </Typography>
          <Typography variant="body1">
            {dayjs.unix(document.recieveInChinaDate).format('DD/MM/YYYY')}
          </Typography>
        </Box>
        <Box sx={style.cell}>
          <Typography sx={style.label} variant="h6">
            Дата відправки
          </Typography>
          <Typography variant="body1">
            {dayjs.unix(document.sendDate).format('DD/MM/YYYY')}
          </Typography>
        </Box>
        {document.recieveInUkraineDate && (
          <Box sx={style.cell}>
            <Typography sx={style.label} variant="h6">
              Дата прибуття в Україну
            </Typography>
            <Typography variant="body1">
              {dayjs.unix(document.recieveInUkraineDate).format('DD/MM/YYYY')}
            </Typography>
          </Box>
        )}
        {document.additionalValue && (
          <Box sx={style.cell}>
            <Typography sx={style.label} variant="h6">
              Додаткові витрати
            </Typography>
            <Typography variant="body1">{document.additionalValue}</Typography>
          </Box>
        )}
        {document.additionalComment && (
          <Box sx={style.cell}>
            <Typography sx={style.label} variant="h6">
              Комментар
            </Typography>
            <Typography variant="body1">
              {document.additionalComment}
            </Typography>
          </Box>
        )}
        {document?.photos && (
          <Box sx={style.imageCell}>
            <ImageList cols={1}>
              {document.photos.map((photo) => (
                <ImageListItem key={photo.id}>
                  <img src={photo.photoInBase64} alt="Loading..." />
                </ImageListItem>
              ))}
            </ImageList>
          </Box>
        )}
      </Box>
    </Modal>
  );
};
