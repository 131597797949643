import { SxProps, Theme } from '@mui/material';

export const navbarStyle: SxProps<Theme> = {
  width: '100%',
  height: '60px',
  zIndex: '10',

  position: 'sticky',
  top: 0,

  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',

  backgroundColor: 'secondary.main',
};

export const logoStyle: SxProps<Theme> = {
  marginLeft: '20px',

  fontWeight: 'bold',
  fontSize: '40px',
  color: 'primary.main',
  cursor: 'pointer',
};

export const buttonStyle: SxProps<Theme> = {
  width: '60px',
  height: '60px',
};
