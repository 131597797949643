import { SxProps, Theme } from "@mui/material";

interface IManageDeliveriesStyle {
  container: SxProps<Theme>;
  filtersContainer: SxProps<Theme>;
  tableContainer: SxProps<Theme>;
  button: SxProps<Theme>;
}

export const manageDeliveriesStyleDesktop: IManageDeliveriesStyle = {
  container: {
    width: "100vw",
    height: "100dvh",
    padding: 0,
    margin: 0,
    display: "flex",
    alignItems: "flex-start",
    paddingTop: "50px",
  },
  filtersContainer: {
    width: "20%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    paddingLeft: "30px",
  },
  tableContainer: {
    width: "80%",
    height: "60%",
  },
  button: {
    marginTop: "10px",
  },
};

export const manageDeliveriesStyleMobile: IManageDeliveriesStyle = {
  container: {
    width: "100vw",
    height: "100dvh",
    maxHeight: "100dvh",
    padding: 0,
    margin: 0,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  filtersContainer: {
    height: "20%",
    width: "90%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-around",
    paddingTop: "15px",
    marginTop: "15px",
  },
  tableContainer: {
    height: "60%",
    width: "100%",
    display: "flex",
    marginTop: "20px",
    alignItems: "flex-start",
    justifyContent: "center",
  },
  button: {
    marginTop: "10px",
    marginBottom: "10px",
  },
};
