import { IUserCreate } from '../../Types/User/user.create';
import { IUserUpdate } from '../../Types/User/user.update';
import { Roles } from '../../Types/roles';

export const initialValues: IUserCreate & IUserUpdate = {
  id: 0,
  email: '',
  name: '',
  role: Roles.Client,
  surname: '',
  clientNumber: '',
  balance: '',
  tarif: '',
  storageAdressInChina: '',
  storageAdressInUkraine: '',
  managerName: '',
  managerSurname: '',
  managerPhone: '',
  password: '',
};
