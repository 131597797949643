import { Box, Button, Typography } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { userStyle } from './style';
import { IApiUser } from '../../Types/User/api.user';

interface IUser extends IApiUser {
  handleEdit: (user: IApiUser) => void;
  handleDelete: (id: number) => void;
}

export const User = ({ handleEdit, handleDelete, ...user }: IUser) => {
  return (
    <Box onClick={() => handleEdit(user)} sx={userStyle.container}>
      <Box sx={{ ...userStyle.cell, color: 'lightblue' }}>
        <Typography>{user.clientNumber}</Typography>
      </Box>
      <Box sx={userStyle.cell}>
        <Typography>{user.name}</Typography>
      </Box>
      <Box sx={{ ...userStyle.cell, color: 'green' }}>
        <Typography>{user.balance}</Typography>
      </Box>
      {/* <Box sx={userStyle.cell}>
        <Button
          variant="contained"
          color="error"
          sx={userStyle.button}
          onClick={() => handleDelete(user.id)}
        >
          <DeleteForeverIcon sx={userStyle.icons} />
        </Button>
      </Box> */}
    </Box>
  );
};
