import { Box, Button, useMediaQuery } from '@mui/material';
import { useQuery, useMutation } from '@tanstack/react-query';
import {
  manageDeliveriesStyleDesktop,
  manageDeliveriesStyleMobile,
} from './style';
import { SearchField } from '../SearchField/SearchField';
import { UserSelect } from '../UserSelect/UserSelect';
import { useEffect, useState } from 'react';
import { IApiUser } from '../../Types/User/api.user';
import {
  createDocument,
  deleteDocument,
  getDocumentsByUserId,
  updateDocument,
} from '../../Service/axios/document.api';
import { IApiPagination } from '../../Types/Pagination/api.pagination';
import { IApiDocument } from '../../Types/Document/api.document';
import { applicationStore } from '../../Store/applicationStore';
import { DocumentsTable } from '../DocumentsTable/DocumentsTable';
import { ManageDocumentModal } from '../ManageDocumentModal/ManageDocumentModal';
import { OrderBy } from '../../Types/orderType';
import { IDocumentCreate } from '../../Types/Document/document.create';
import { IDocumentUpdate } from '../../Types/Document/document.update';

export const ManageDeliveries = () => {
  const { searchId } = applicationStore();
  const [open, setOpen] = useState(false);
  const [currentUser, setCurrentUser] = useState<IApiUser | null>(null);
  const [currentDocument, setCurrentDocument] = useState<IApiDocument>();
  const [page, setPage] = useState(1);
  const [desc, setDesc] = useState(false);
  const [orderBy, setOrderBy] = useState(OrderBy.id);
  const {
    data: response,
    refetch,
    isLoading,
  } = useQuery<IApiPagination<IApiDocument>>({
    queryKey: ['documents', currentUser?.id, searchId, page, desc, orderBy],
    queryFn: () =>
      getDocumentsByUserId({
        id: currentUser?.id,
        page,
        searchId,
        limit: 10,
        desc,
        orderBy,
      }),
    enabled: false,
  });
  const createDocumentMutation = useMutation(createDocument, {
    onSuccess: () => {
      handleClose();
      refetch();
    },
  });
  const updateDocumentMutation = useMutation(updateDocument, {
    onSuccess: () => refetch(),
  });
  const deleteDocumentMutation = useMutation(deleteDocument, {
    onSuccess: () => {
      setOpen(false);
      setCurrentDocument(undefined);
      refetch();
    },
  });

  useEffect(() => {
    if (currentUser) {
      refetch();
    }
  }, [page, currentUser, searchId, desc, orderBy]);

  const handleDeleteDocument = (id: number) => {
    deleteDocumentMutation.mutate(id);
  };

  const handleSubmit = ({
    userId,
    id,
    ...document
  }: IDocumentCreate & IDocumentUpdate) => {
    currentDocument
      ? updateDocumentMutation.mutate({ id, ...document })
      : createDocumentMutation.mutate({ userId, ...document });
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = (callback?: () => void) => {
    setOpen(false);
    currentUser && setCurrentDocument(undefined);
    callback && callback();
  };

  useEffect(() => {
    currentDocument && setOpen(true);
  }, [currentDocument]);

  useEffect(() => {
    if (currentDocument && open) {
      setCurrentDocument(
        response?.items.find((doc) => doc.id === currentDocument.id)
      );
    }
  }, [response]);

  const matches = useMediaQuery('(max-width:1000px)');

  const style = matches
    ? manageDeliveriesStyleMobile
    : manageDeliveriesStyleDesktop;

  const loading =
    isLoading ||
    createDocumentMutation.isLoading ||
    updateDocumentMutation.isLoading ||
    deleteDocumentMutation.isLoading;

  return (
    <Box sx={style.container}>
      <Box sx={style.filtersContainer}>
        <SearchField />
        <UserSelect setUser={setCurrentUser} />
        <Button
          sx={style.button}
          fullWidth
          variant="contained"
          color="secondary"
          disabled={!currentUser || loading}
          onClick={handleOpen}
        >
          Створити документ
        </Button>
      </Box>
      <Box sx={style.tableContainer}>
        <DocumentsTable
          page={page}
          setPage={setPage}
          desc={desc}
          setDesc={setDesc}
          orderBy={orderBy}
          setOrderBy={setOrderBy}
          items={response?.items}
          meta={response?.meta}
          setCurrentDocument={setCurrentDocument}
        />
      </Box>
      {currentUser && (
        <ManageDocumentModal
          handleSubmit={handleSubmit}
          userId={currentUser.id}
          open={open}
          handleClose={handleClose}
          document={currentDocument}
          handleDeleteDocument={handleDeleteDocument}
          refetch={refetch}
          isLoading={loading}
        />
      )}
    </Box>
  );
};
