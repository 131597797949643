import { Route, Routes } from "react-router-dom";

import { ThemeProvider } from "@emotion/react";

import { PrivateRoute } from "./Service/PrivateRoute";
import { LayoutRoute } from "./Service/LayoutRoute";

import { LoginPage } from "./Pages/Login/LoginPage";
import { HomePage } from "./Pages/Home/HomePage";
import { DeliveriesPage } from "./Pages/Deliveries/DeliveriesPage";

import { useUserStore } from "./Store/userStore";
import { useGetUser } from "./Service/Hooks/useGetUser";

import { getFromStorage } from "./Service/localstorage";
import { LOCAL_STORAGE_KEY } from "./Constants/constants";

import { theme } from "./Style/theme";

export const App = () => {
  const { user } = useUserStore();
  const [refetch] = useGetUser();

  if (getFromStorage({ key: LOCAL_STORAGE_KEY })) refetch();

  return (
    <ThemeProvider theme={theme}>
      <Routes>
        {!user && <Route path="/" element={<LoginPage />} />}
        <Route element={<PrivateRoute />}>
          <Route element={<LayoutRoute />}>
            <Route path="/home" element={<HomePage />} />
            <Route path="/deliveries" element={<DeliveriesPage />} />
            <Route path="*" element={<HomePage />} />
          </Route>
        </Route>
      </Routes>
    </ThemeProvider>
  );
};
