import {
  Autocomplete,
  AutocompleteRenderOptionState,
  TextField,
} from '@mui/material';
import { useInfiniteQuery } from '@tanstack/react-query';
import { IApiPagination } from '../../Types/Pagination/api.pagination';
import { IApiUser } from '../../Types/User/api.user';
import { getAllUsers } from '../../Service/axios/user.api';
import { useEffect, useState } from 'react';
import { Waypoint } from 'react-waypoint';

interface IUserSelect {
  setUser: (user: IApiUser | null) => void;
}

export const UserSelect = ({ setUser }: IUserSelect) => {
  const [options, setOptions] = useState<IApiUser[]>([]);

  const {
    data: response,
    isLoading,
    hasNextPage,
    fetchNextPage,
    isFetching,
  } = useInfiniteQuery<IApiPagination<IApiUser>>({
    queryKey: ['users'],
    queryFn: getAllUsers,
    getNextPageParam: ({ meta: { currentPage, totalPages } }) =>
      (currentPage < totalPages && currentPage + 1) || undefined,
  });

  useEffect(() => {
    if (!isLoading || !isFetching) {
      setOptions(
        response?.pages.map((page) => page.items.map((user) => user)).flat() ||
          []
      );
    }
  }, [response]);

  const renderLi = (
    params: React.HTMLAttributes<HTMLLIElement>,
    user: IApiUser
  ) => <li {...params}>{user.name + ' ' + user.surname}</li>;

  const renderOption = (
    params: React.HTMLAttributes<HTMLLIElement>,
    user: IApiUser,
    state: AutocompleteRenderOptionState
  ) => {
    return state.index === options.length - 1 && hasNextPage ? (
      <Waypoint key={user.id} onEnter={() => hasNextPage && fetchNextPage()}>
        {renderLi(params, user)}
      </Waypoint>
    ) : (
      renderLi(params, user)
    );
  };

  const getOptionLabel = (user: IApiUser) =>
    user.name + ' ' + user.clientNumber;

  return (
    <Autocomplete
      fullWidth
      onChange={(event, user) => setUser(user)}
      renderOption={renderOption}
      renderInput={(params) => (
        <TextField
          {...params}
          color="secondary"
          variant="standard"
          label="Клієнти"
        />
      )}
      options={options}
      getOptionLabel={getOptionLabel}
    />
  );
};
