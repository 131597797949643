import { SxProps, Theme } from '@mui/material';

interface IUserStyle {
  container: SxProps<Theme>;
  cell: SxProps<Theme>;
  button: SxProps<Theme>;
  icons: SxProps<Theme>;
}

export const userStyle: IUserStyle = {
  container: {
    color: 'white',
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'space-around',
    backgroundColor: 'primary.dark',
    padding: '15px 0px 15px 0px',
    marginBottom: '5px',
  },
  cell: {
    width: '30%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  button: {
    width: '45px',
    padding: '5px',
    minWidth: '0px',
  },
  icons: {
    fontSize: '20px',
  },
};
