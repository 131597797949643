import { IApiPhoto } from '../../Types/Photo/api.photo';
import { IPhotoCreate } from '../../Types/Photo/photo.create';
import { instance } from './axios';

export const createPhotos = async (photo: IPhotoCreate) => {
  return instance.post<IApiPhoto[]>(`document/photos`, photo);
};

export const deletePhoto = async (id: number) => {
  return instance.delete(`document/photo/${id}`);
};
