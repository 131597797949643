import { SxProps, Theme } from '@mui/material';

export const menuStyle: SxProps<Theme> = {
  width: '10%',
  minWidth: '100px',
  height: '100dvh',

  position: 'absolute',
  top: '0',
  right: '0',

  paddingTop: '60px',

  display: 'flex',
  flexDirection: 'column',

  boxSizing: 'border-box',

  backgroundColor: 'secondary.dark',
};

export const buttonStyle: SxProps<Theme> = {
  height: '40px',

  borderRadius: '0px',

  fontWeight: 'bold',
  color: 'primary.main',
};
