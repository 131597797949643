import {
  Box,
  Table,
  TableHead,
  TableBody,
  TableContainer,
  TableCell,
  TableRow,
  Pagination,
  Typography,
} from '@mui/material';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import dayjs from 'dayjs';
import useMediaQuery from '@mui/material/useMediaQuery';

import { IApiPagination } from '../../Types/Pagination/api.pagination';

import { IApiDocument } from '../../Types/Document/api.document';
import { useState } from 'react';
import { OrderBy } from '../../Types/orderType';
import { documentsTableStyleDesktop, documentsTableStyleMobile } from './style';
import { Status } from '../../Types/status.enum';

interface IDocumentsList extends Partial<IApiPagination<IApiDocument>> {
  page: number;
  setPage: (page: number) => void;
  orderBy: OrderBy;
  setOrderBy: (orderBy: OrderBy) => void;
  desc: boolean;
  setDesc: (desc: boolean) => void;
  setCurrentDocument?: (document: IApiDocument) => void;
}

const icons = {
  [Status.inChina]: (
    <Typography sx={{ color: 'red' }} variant="body1">
      CN
    </Typography>
  ),
  [Status.inProgress]: '✈️',
  [Status.paymentPending]: (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Typography sx={{ color: 'blue' }} variant="body1">
        U
      </Typography>
      <Typography sx={{ color: 'yellow' }} variant="body1">
        A
      </Typography>
    </Box>
  ),
  [Status.completed]: '✅',
};

export const DocumentsTable = ({
  items,
  meta,
  setPage,
  page,
  setOrderBy,
  orderBy,
  setDesc,
  desc,
  setCurrentDocument,
}: IDocumentsList) => {
  const handleSortClick = (value: OrderBy) => {
    if (orderBy === value) {
      setDesc(!desc);
    } else {
      setOrderBy(value);
    }
  };

  const matches = useMediaQuery('(max-width:480px)');

  const style = matches
    ? documentsTableStyleMobile
    : documentsTableStyleDesktop;

  return (
    <TableContainer sx={style.container}>
      <Table>
        <TableHead sx={style.header.container}>
          <TableRow sx={style.row}>
            <TableCell sx={style.header.cell}>Статус</TableCell>
            <TableCell sx={style.header.cell}>Номер вантажу</TableCell>
            <TableCell sx={style.header.cell}>Вага (кг)</TableCell>
            <TableCell sx={style.header.cell}>Кількість місць</TableCell>
            <TableCell
              sx={style.header.sortCell}
              onClick={() => handleSortClick(OrderBy.recieveInChinaDate)}
            >
              <Box sx={style.header.sortCellContainer}>
                Дата отримання в Китаї
                <ArrowUpwardIcon
                  sx={
                    {
                      ...style.header.icon,
                      ...(desc && style.header.rotateIcon),
                      ...(orderBy !== OrderBy.recieveInChinaDate &&
                        style.header.invisibleIcon),
                    } || {}
                  }
                />
              </Box>
            </TableCell>
            <TableCell
              sx={style.header.sortCell}
              onClick={() => handleSortClick(OrderBy.sendDate)}
            >
              <Box sx={style.header.sortCellContainer}>
                Дата відправки
                <ArrowUpwardIcon
                  sx={
                    {
                      ...style.header.icon,
                      ...(desc && style.header.rotateIcon),
                      ...(orderBy !== OrderBy.sendDate &&
                        style.header.invisibleIcon),
                    } || {}
                  }
                />
              </Box>
            </TableCell>
            <TableCell
              sx={style.header.sortCell}
              onClick={() => handleSortClick(OrderBy.recieveInUkraineDate)}
            >
              <Box sx={style.header.sortCellContainer}>
                Дата прибуття в Україну
                <ArrowUpwardIcon
                  sx={
                    {
                      ...style.header.icon,
                      ...(desc && style.header.rotateIcon),
                      ...(orderBy !== OrderBy.recieveInUkraineDate &&
                        style.header.invisibleIcon),
                    } || {}
                  }
                />
              </Box>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {items?.map((doc: IApiDocument) => (
            <TableRow
              onClick={() => setCurrentDocument && setCurrentDocument(doc)}
              key={doc.id}
              sx={style.row}
            >
              <TableCell sx={style.tableCell}>
                {doc.status} {icons[doc.status]}
              </TableCell>
              <TableCell sx={style.tableCell}>{doc.cargoId}</TableCell>
              <TableCell sx={style.tableCell}>{doc.cargoWeight}</TableCell>
              <TableCell sx={style.tableCell}>{doc.placeQuantity}</TableCell>
              <TableCell sx={style.tableCell}>
                {dayjs.unix(doc.recieveInChinaDate).format('DD/MM/YYYY')}
              </TableCell>
              <TableCell sx={style.tableCell}>
                {dayjs.unix(doc.sendDate).format('DD/MM/YYYY')}
              </TableCell>
              <TableCell sx={style.tableCell}>
                {doc.recieveInUkraineDate
                  ? dayjs.unix(doc.recieveInUkraineDate).format('DD/MM/YYYY')
                  : '-'}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Pagination
        count={meta?.totalPages}
        onChange={(e, page) => setPage(page)}
        sx={style.pagination}
        page={page}
      />
    </TableContainer>
  );
};
