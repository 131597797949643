import { SxProps, Theme } from '@mui/material';

interface IManageDocumentModalStyle {
  container: SxProps<Theme>;
  form: SxProps<Theme>;
  column: SxProps<Theme>;
  field: SxProps<Theme>;
  row: SxProps<Theme>;
  buttonsContaner: SxProps<Theme>;
  button?: SxProps<Theme>;
  input?: React.CSSProperties;
  label?: React.CSSProperties;
  date?: React.CSSProperties;
}

export const manageDocumentModalStyleDesktop: IManageDocumentModalStyle = {
  container: {
    position: 'absolute',
    minWidth: '450px',
    maxHeight: '80dvh',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    padding: '50px',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    borderRadius: '10px',
    backgroundColor: 'primary.light',
    overflowY: 'scroll',
    scrollbarWidth: '0',
    '::-webkit-scrollbar': {
      width: 0,
      height: 0,
    },
  },
  form: {
    width: '100%',
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
  },
  column: {
    width: '45%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  field: {
    marginBottom: '10px',
  },
  row: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '20px',
  },
  buttonsContaner: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
    flexDirection: 'row-reverse',
    marginTop: '15px',
  },
};

export const manageDocumentModalStyleMobile: IManageDocumentModalStyle = {
  container: {
    position: 'absolute',
    width: '95vw',
    maxHeight: '500px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    padding: '30px 0px 30px 0px',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    borderRadius: '10px',
    backgroundColor: 'primary.light',
    overflowY: 'scroll',
    scrollbarWidth: '0',
    '::-webkit-scrollbar': {
      width: 0,
      height: 0,
    },
  },
  form: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'column',
  },
  column: {
    width: '80%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  field: {
    marginBottom: '15px',
  },
  input: {
    fontSize: '24px',
  },
  label: {
    fontSize: '20px',
  },
  row: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '20px',
  },
  date: {
    fontSize: '24px',
  },
  buttonsContaner: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    marginTop: '15px',
  },
  button: {
    fontSize: '18px',
    width: '70%',
    marginBottom: '10px',
  },
};
