import { SxProps, Theme } from "@mui/material";

export const pageStyle: SxProps<Theme> = {
  width: "100dvw",
  height: "100dvh",

  padding: 0,
  margin: 0,

  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  alignItems: "center",

  backgroundColor: "primary.main",
};

export const bodyStyle: SxProps<Theme> = {
  width: "100%",
  height: "100%",
  display: "flex",
};
