import { SxProps, Theme } from "@mui/material/";

export const containerStyle: SxProps<Theme> = {
  margin: "20px",
  marginLeft: "40px",
  width: "15%",
};

export const searchFieldStyle: SxProps<Theme> = {
  input: { color: "white" },
  color: "secondary.main",
};
