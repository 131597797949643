import { SxProps, Theme } from '@mui/material';
interface IDocumentModalStyle {
  container: SxProps<Theme>;
  label: SxProps<Theme>;
  cell: SxProps<Theme>;
  imageCell: SxProps<Theme>;
}

export const documentModalStyleDesktop: IDocumentModalStyle = {
  container: {
    position: 'absolute',
    minWidth: '500px',
    maxWidth: '500px',
    maxHeight: '80dvh',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    padding: '50px',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    borderRadius: '10px',
    backgroundColor: 'primary.light',
    overflowY: 'scroll',
    scrollbarWidth: '0',
    '::-webkit-scrollbar': {
      width: 0,
      height: 0,
    },
  },
  label: {
    color: 'secondary.main',
  },
  cell: {
    width: '100%',
    marginBottom: '10px',
    color: 'white',
    fontSize: '18px',
  },
  imageCell: {
    width: '100%',
  },
};

export const documentModalStyleMobile: IDocumentModalStyle = {
  container: {
    position: 'absolute',
    width: '90vw',
    maxHeight: '500px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    padding: '30px 0px 30px 0px',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    borderRadius: '10px',
    backgroundColor: 'primary.light',
    overflow: 'hidden',
    overflowY: 'scroll',
    scrollbarWidth: '0',
    '::-webkit-scrollbar': {
      width: 0,
      height: 0,
    },
  },
  label: {
    color: 'secondary.main',
    fontSize: '20px',
  },
  cell: {
    width: '90%',
    color: 'white',
    fontSize: '30px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
  },
  imageCell: {
    width: '100%',
  },
};
