import { Box, IconButton, Typography } from '@mui/material';

import MenuIcon from '@mui/icons-material/Menu';

import { applicationStore } from '../../Store/applicationStore';

import { navbarStyle, logoStyle, buttonStyle } from './HeaderStyle';
import { useNavigate } from 'react-router-dom';

export const Header = () => {
  const { setMenuOnScreen } = applicationStore();
  const navigate = useNavigate();

  const handleMenuBtnClick = () => {
    setMenuOnScreen();
  };

  const handleNavigateClick = (route: string) => {
    navigate(route);
  };

  return (
    <Box sx={navbarStyle}>
      <Typography onClick={() => handleNavigateClick('/home')} sx={logoStyle}>
        M2K
      </Typography>
      <IconButton sx={buttonStyle} onClick={handleMenuBtnClick}>
        <MenuIcon />
      </IconButton>
    </Box>
  );
};
