import { SxProps, Theme } from "@mui/material";

export const formStyle: SxProps<Theme> = {
  width: "275px",
  height: "275px",

  padding: "50px 35px",

  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "space-between",

  backgroundColor: "primary.main",

  borderRadius: "15px",
};

export const inputStyle: SxProps<Theme> = {
  width: "80%",

  input: { color: "white" },

  "&: focus-within": {
    "& .passwordIcon": {
      color: "secondary.main",
    },
  },
};

export const buttonStyle: SxProps<Theme> = {
  fontWeight: "bold",
};

export const iconStyle: SxProps<Theme> = {
  color: "primary.dark",
};

export const errorMessageStyle: SxProps<Theme> = {
  color: "red",
  textAlign: "center",
  fontWeight: "red",
};
