import { useEffect } from 'react';

import { useQuery } from '@tanstack/react-query';

import { getUserInfoFn } from '../axios/axios';

import { useUserStore } from '../../Store/userStore';

import { getFromStorage } from './../localstorage';
import { LOCAL_STORAGE_KEY } from '../../Constants/constants';

import { IUserInfo } from '../../Components/PersonalInfo/PersonalInfoType';

export const useGetUser = (): any => {
  const { user, setUser } = useUserStore();

  const { refetch, data, isSuccess, isLoading, isError } = useQuery<IUserInfo>({
    queryKey: ['clientInfo'],
    queryFn: getUserInfoFn,
    enabled: false,
  });

  useEffect(() => {
    if (!user && getFromStorage({ key: LOCAL_STORAGE_KEY }) && isSuccess) {
      setUser(data);
    }
  }, [data]);

  return [refetch, isSuccess, isLoading, isError];
};
