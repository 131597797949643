import useMediaQuery from '@mui/material/useMediaQuery';

import { Box, Typography } from '@mui/material';

import { useUserStore } from '../../Store/userStore';

import {
  personalInfoStyleDesktop,
  personalInfoStyleMobile,
} from './PersonalInfoStyle';

export const PersonalInfo = () => {
  const { user } = useUserStore();
  const matches = useMediaQuery('(max-width:480px)');

  const style = matches ? personalInfoStyleMobile : personalInfoStyleDesktop;

  return (
    <Box sx={style.containerStyle}>
      <Typography sx={style.sectionLabelStyle}>
        Персональна інформація
      </Typography>
      <Typography sx={style.fieldLabelStyle}>П.І.Б.</Typography>
      <Typography sx={style.infoStyle}>
        {user?.surname + ' ' + user?.name}
      </Typography>
      <Typography sx={style.fieldLabelStyle}>Код клієнта</Typography>
      <Typography sx={style.infoStyle}>{user?.clientNumber}</Typography>
      <Typography sx={style.fieldLabelStyle}>Баланс</Typography>
      <Typography sx={style.infoStyle}>{user?.balance}</Typography>
      <Typography sx={style.fieldLabelStyle}>Тариф</Typography>
      <Typography sx={style.infoStyle}>{user?.tarif}</Typography>
      <Typography sx={style.fieldLabelStyle}>
        Адреса доставки в Україні
      </Typography>
      <Typography sx={style.infoStyle}>
        {user?.storageAdressInUkraine}
      </Typography>
      <Typography sx={style.fieldLabelStyle}>Адреса складу в Китаї</Typography>
      <Typography sx={style.infoStyle}>{user?.storageAdressInChina}</Typography>
      <Typography sx={style.sectionLabelStyle}>Контактна інформація</Typography>
      <Typography sx={style.fieldLabelStyle}>Ім'я менеджера</Typography>
      <Typography sx={style.infoStyle}>
        {user?.managerSurname + ' ' + user?.managerName}
      </Typography>
      <Typography sx={style.fieldLabelStyle}>Телефон менеджера</Typography>
      <Typography sx={style.infoStyle}>{user?.managerPhone}</Typography>
    </Box>
  );
};
