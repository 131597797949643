import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMutation } from '@tanstack/react-query';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import { AxiosError } from 'axios';
import { IAxiosErrorData } from '../../Types/axiosErrorType';

import {
  Box,
  Button,
  TextField,
  Typography,
  InputAdornment,
  IconButton,
} from '@mui/material';

import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

import { useGetUser } from '../../Service/Hooks/useGetUser';
import { applicationStore } from '../../Store/applicationStore';

import { loginUserFn } from '../../Service/axios/axios';

import { saveInStorage } from '../../Service/localstorage';
import { LOCAL_STORAGE_KEY } from '../../Constants/constants';

import { ILoginInput, ILoginResponse } from './AuthFromType';

import {
  formStyle,
  inputStyle,
  iconStyle,
  buttonStyle,
  errorMessageStyle,
} from './AuthFromStyle';

const signInSchema = Yup.object().shape({
  email: Yup.string().email('Невірна пошта').required(),
  password: Yup.string()
    .min(6, 'Невірний пароль')
    .max(50, 'Невірний пароль')
    .required(),
});

export const AuthForm = () => {
  const navigate = useNavigate();
  const [refetch] = useGetUser();
  const { passwordIsVisible, setPasswordIsVisible, error, setError } =
    applicationStore();

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: signInSchema,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: (values) => {
      authUser.mutate(values);
      navigate('/home', { replace: true });
    },
  });

  useEffect(() => {
    if (error) setError('');
  }, [formik.errors.email, formik.errors.email]);

  const authUser = useMutation(
    (userData: ILoginInput) => loginUserFn(userData),
    {
      onSuccess: (data: ILoginResponse) => {
        saveInStorage({ key: LOCAL_STORAGE_KEY, value: data.access_token });
        refetch();
      },
      onError: (error: AxiosError<IAxiosErrorData>) => {
        if (error.response?.data?.message) {
          setError(error.response.data.message);
        } else {
          setError('Unknown error');
        }
      },
    }
  );

  const handleMouseDownEvent = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const handlePasswordVisible = () => {
    setPasswordIsVisible();
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <Box sx={formStyle}>
        <Typography variant="h4" color="secondary">
          Авторизація
        </Typography>
        <TextField
          id="email"
          name="email"
          sx={inputStyle}
          variant="standard"
          label="e-mail"
          color="secondary"
          autoComplete="off"
          onChange={formik.handleChange}
          value={formik.values.email}
          error={Boolean(formik.errors.email)}
          helperText={formik.errors.email}
        />
        <TextField
          id="password"
          name="password"
          sx={inputStyle}
          variant="standard"
          type={passwordIsVisible ? 'text' : 'password'}
          label="Пароль"
          color="secondary"
          autoComplete="off"
          onChange={formik.handleChange}
          value={formik.values.password}
          error={Boolean(formik.errors.password)}
          helperText={formik.errors.password}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handlePasswordVisible}
                  onMouseDown={handleMouseDownEvent}
                  onMouseUp={handleMouseDownEvent}
                  edge="end"
                >
                  {passwordIsVisible ? (
                    <VisibilityIcon className="passwordIcon" sx={iconStyle} />
                  ) : (
                    <VisibilityOffIcon
                      className="passwordIcon"
                      sx={iconStyle}
                    />
                  )}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        {error && <Typography sx={errorMessageStyle}>{error}</Typography>}
        <Button
          sx={buttonStyle}
          variant="contained"
          color="secondary"
          type="submit"
        >
          Увійти
        </Button>
      </Box>
    </form>
  );
};
