import { SxProps, Theme } from '@mui/material';

interface IManageUserStyle {
  container: SxProps<Theme>;
  usersList: SxProps<Theme>;
  header: SxProps<Theme>;
  headerCell: SxProps<Theme>;
  button: SxProps<Theme>;
}

export const manageUsersStyleDesktop: IManageUserStyle = {
  container: {
    width: '100%',
    maxWidth: '700px',
    maxHeight: '700px',
    marginTop: '50px',
    display: 'flex',
    flexDirection: 'column',
    borderRadius: '10px',
  },
  usersList: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    maxHeight: '600px',
    overflowY: 'scroll',
    scrollbarWidth: '0',
    '::-webkit-scrollbar': {
      width: 0,
      height: 0,
    },
    paddingTop: '20px',
    marginBottom: '20px',
  },
  header: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
    fontSize: '18px',
    backgroundColor: 'secondary.dark',
    padding: '15px 0px 15px 0px',
    fontWeight: 'bold',
  },
  headerCell: {
    color: 'white',
    width: '30%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  button: {
    marginTop: '10px',
    position: 'absolute',
    left: '50%',
    bottom: '50px',
    transform: 'translate(-50%,0)',
  },
};

export const manageUsersStyleMobile: IManageUserStyle = {
  container: {
    width: '100%',
    maxWidth: '700px',
    maxHeight: '700px',
    marginTop: '50px',
    display: 'flex',
    flexDirection: 'column',
    borderRadius: '10px',
  },
  usersList: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    maxHeight: '600px',
    overflowY: 'scroll',
    scrollbarWidth: '0',
    '::-webkit-scrollbar': {
      width: 0,
      height: 0,
    },
    paddingTop: '20px',
    marginBottom: '20px',
  },
  header: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
    fontSize: '18px',
    backgroundColor: 'secondary.dark',
    padding: '15px 0px 15px 0px',
    fontWeight: 'bold',
  },
  headerCell: {
    color: 'white',
    width: '30%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  button: {
    marginTop: '10px',
    position: 'absolute',
    left: '50%',
    bottom: '50px',
    transform: 'translate(-50%,0)',
  },
};
