import { SxProps, Theme } from '@mui/material';

interface IConfirmationModalStyle {
  container: SxProps<Theme>;
  row: SxProps<Theme>;
}

export const confirmationModalStyle: IConfirmationModalStyle = {
  container: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    minWidth: '250px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '25px',
    borderRadius: '10px',
    backgroundColor: 'primary.light',
  },
  row: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
    marginTop: '20px',
  },
};
